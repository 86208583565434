<template>
  <div class="personal-class">
    <div class="public-div">
      <div class="public-text">身份：</div>
      <div class="public-label">
        <p style="width: 100%">{{ u.name }}</p>
      </div>
    </div>
    <div class="public-div">
      <div class="public-text">工号：</div>
      <div class="public-label">
        <p style="width: 100%" @click="setnumber">{{ u.number }}（点击修改）</p>
      </div>
    </div>
    <el-divider content-position="center">历史提问</el-divider>

    <div class="personal-body">
      <div
        class="card-class"
        v-for="(item, key) in searchdata"
        :key="'' + key"
        @click="gotosee(item)"
      >
        <div class="card-text-public title">{{ item.title }}</div>
        <div class="card-text-public context">
          {{ item.upcontent }}
        </div>
        <div class="card-text-public other">
          {{ "浏览量：" + item.browse + " | 点赞量：" + item.number }}
        </div>
      </div>
    </div>

    <el-divider content-position="center">
      <router-link class="na-class" to="/edition">关于我们</router-link>
    </el-divider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      searchtype: "",
      searchdata: [],

      u: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$axios
        .get("/appealapi/getu.php?o=" + sessionStorage.getItem("openid"))
        .then((res) => {
          this.$set(this, "u", res.data);
        });
      this.$axios
        .get(
          "/appealapi/getall2user.php?u=" + sessionStorage.getItem("username")
        )
        .then((res) => {
          this.searchdata = res.data;
        });
    },
    gotosee(item) {
      this.$router.push({ path: "/details", query: { id: item.id } });
    },

    setnumber() {
      this.$prompt("请输入工号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$axios
            .get(
              "/appealapi/setun.php?o=" +
                sessionStorage.getItem("openid") +
                "&n=" +
                value
            )
            .then((res) => {
              if (res.data == "success") {
                this.init();
                this.$message.success("修改成功");
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .personal-body {
    // height: calc(100% - 80px);
    width: 96%;
    margin: 10px 0px;
    .card-class {
      width: 100%;
      border-bottom: 1px solid#EEEEEE;
    }
    .title {
      font-size: 17px;
    }
    .context {
      font-size: 13px;
      color: #a9a9a9;
    }
    .other {
      font-size: 13px;
      color: #d6d6d6;
    }
  }
  .na-class {
    color: #bbbbbb;
  }
}
</style>